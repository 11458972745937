import TextTransition, { presets } from 'react-text-transition';
import './App.css';
import { Parallax, ParallaxBanner, useParallax } from 'react-scroll-parallax';
import emailjs from '@emailjs/browser';

import React, { useEffect, useRef, useState } from 'react';

function App() {
    const TEXTS = ['Tranquility', 'Serenity', 'Peace', 'Calmness'];
    const TEXTS2 = ['Travellers', 'Enterpreneurs', 'Digital Nomads', 'Remote Workers', 'Creatives', 'Enthusiasts'];
    const [index, setIndex] = React.useState(0);
    const [index2, setIndex2] = React.useState(0);
    // const parallax = useParallax({
    //     rotate: [0, 360],
    // });

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex(index => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex2(index2 => index2 + 1),
            2000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    const homeRef = useRef(null);
    const visionRef = useRef(null);
    const aboutRef = useRef(null);
    const teamRef = useRef(null);
    const contactRef = useRef(null);

    const executeHomeScroll = () => homeRef.current.scrollIntoView();
    const visionScroll = () => visionRef.current.scrollIntoView();
    const executeAboutScroll = () => aboutRef.current.scrollIntoView();
    const executeTeamScroll = () => teamRef.current.scrollIntoView();
    const executeContactScroll = () => contactRef.current.scrollIntoView();

    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const form = useRef();
    const sendEmail = e => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
        setLoading(true);

        emailjs.sendForm('service_ikj0sh8', 'template_9tdxnor', form.current, '3BhS4CKPvqXFzwhXE').then(
            result => {
                // show the user a success message
                setEmailSent(true);
                setLoading(false);
            },
            error => {
                // show the user an error
                setLoading(false);
            },
        );
    };

    return (
        <div className="App">
            <div id="header" className="flex justify-center p-8 absolute top-0 left-0 w-full">
                <div className="flex gap-8 justify-center w-full md:justify-end">
                    <span className="cursor-pointer" onClick={executeHomeScroll}>
                        Home
                    </span>
                    <span className="cursor-pointer" onClick={visionScroll}>
                        Vision
                    </span>
                    <span className="cursor-pointer" onClick={executeAboutScroll}>
                        About
                    </span>
                    {/* <span className="cursor-pointer" onClick={executeTeamScroll}>
                        Team
                    </span> */}
                    <span className="cursor-pointer" onClick={executeContactScroll}>
                        Contact
                    </span>

                    <img src="/images/menu-grid-svgrepo-com.svg" alt="Menu" className="menu hidden md:block" />
                </div>
            </div>
            <div className="section-hero" ref={homeRef}>
                <div className="wrapper h-full">
                    <div className="flex h-full flex-col items-start justify-center">
                        <Parallax speed={20} className="w-full">
                            <div className="flex flex-col items-start md:items-start justify-start">
                                <div className="flex flex-col items-start md:items-start justify-start mb-8 w-full">
                                    <h1 id="company-name" className="m-0">
                                        Bali <span className="green">Hills</span>
                                    </h1>
                                    <span className="text-sm">by Repose</span>
                                </div>

                                <span id="company-tagline" className="font-bold text-4xl text-left md:text-7xl">
                                    <div className="flex flex-row gap-4">
                                        where{' '}
                                        <span className="gradient">
                                            <TextTransition springConfig={presets.stiff}>
                                                {TEXTS[index % TEXTS.length]}
                                            </TextTransition>
                                        </span>
                                    </div>
                                    <span className="text-4xl md:text-6xl">meets productivity.</span>
                                </span>

                                <div className="flex items-start gap-4 mb-24">
                                    <button
                                        className="button button_primary w-40 transition text-white ease-in-out bg-indigo-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 "
                                        onClick={executeContactScroll}
                                    >
                                        <a href="#contact__email" className="font-bold">
                                            Contact
                                        </a>
                                    </button>

                                    <button
                                        className="button button_secondary w-40 transition ease-in-out hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 hover:text-white"
                                        onClick={executeContactScroll}
                                    >
                                        <a href="#contact__email" className="font-bold">
                                            Be an Investor
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </Parallax>

                        <div className="relative" id="mouse-container">
                            <a href="#">
                                <div id="mouse-scroll">
                                    <div className="mouse">
                                        <div className="mouse-in"></div>
                                    </div>
                                    <div>
                                        <span className="down-arrow-1"></span>
                                        <span className="down-arrow-2"></span>
                                        <span className="down-arrow-3"></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section section-about no-slash relative py-10 md:py-40 text-center" ref={visionRef}>
                <div className="magic-pattern opacity-30"></div>

                <div className="wrapper h-full flex">
                    <div className="flex gap-16 items-center">
                        <div className="absolute w-full">
                            <Parallax speed={30}>
                                <h2 className="font-bold m-0 text-center text-5xl md:text-6xl">OUR VISION</h2>
                                <h2 className="text-black-400 font-semibold text-center text-3xl mt-4 md:text-4xl">
                                    is to create a <span className="green">sanctuary</span> for the modern professional.
                                </h2>
                            </Parallax>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section section-vision no-slash relative" ref={aboutRef}>
                <div className="wrapper">
                    <div className="flex flex-col flex-col-reverse md:flex-row gap-4 md:gap-16 items-center mb-40">
                        <div className="relative flex-1 md:items-end md:text-right md:w-1/2">
                            <Parallax speed={-10}>
                                <div className="flex flex-col items-center md:items-end gap-8">
                                    <div>
                                        <h2 className="font-bold m-0 text-6xl md:text-7xl">Welcome</h2>
                                        <h2 className="font-semibold m-0 text-4xl md:text-5xl">
                                            to our idyllic digital
                                            <br /> nomad village in Bali.
                                        </h2>
                                    </div>
                                    <div>
                                        <button
                                            className="font-bold button button_primary px-8 transition text-white ease-in-out bg-indigo-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 "
                                            onClick={executeContactScroll}
                                        >
                                            <a href="#contact__email">Inquire as an Investor</a>
                                        </button>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                        <div className="h-auto md:flex-1">
                            <Parallax speed={60}>
                                <img
                                    src="/images/christopher-alvarenga-IibRZfGCM10-unsplash.jpg"
                                    alt="Bali Hills"
                                    className="rounded shadow-lg"
                                />
                            </Parallax>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 md:gap-16 items-center mb-40">
                        <div className="flex-1 relative">
                            <Parallax speed={50}>
                                <img
                                    src="/images/jenny-ueberberg-BaSeK7rwc1A-unsplash.jpg"
                                    alt="Bali Hills"
                                    className="rounded shadow-lg"
                                />
                            </Parallax>
                        </div>
                        <div className="relative items-center md:items-left text-left md:w-1/3 justify-center">
                            <Parallax speed={10}>
                                <div className="flex flex-col text-center items-center md:items-start md:text-left gap-8">
                                    <div className="text-center items-center justify-center">
                                        <h2 className="font-bold m-0 text-center items-center justify-center md:text-left margin-auto text-3xl md:text-4xl w-full">
                                            <TextTransition
                                                springConfig={presets.stiff}
                                                className="w-full items-center text-center flex justify-center md:items-start md:text-left md:justify-start"
                                            >
                                                for {TEXTS2[index2 % TEXTS2.length]}
                                            </TextTransition>
                                        </h2>
                                        <h2 className="text-black-400 text-2xl font-semibold m-0 text-center md:text-left">
                                            seeking a perfect blend of peace and productivity.
                                        </h2>
                                    </div>
                                    <div>
                                        <button
                                            className="font-bold button button_primary px-8 transition text-white ease-in-out bg-indigo-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 "
                                            onClick={executeContactScroll}
                                        >
                                            <a href="#contact__email">Inquire as a Traveller</a>
                                        </button>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse md:flex-row gap-4 md:gap-16 items-center mb-40">
                        <div className="flex-1 items-center text-center md:items-end md:text-right">
                            <Parallax speed={-10}>
                                <div className="flex flex-col items-center md:items-end gap-8">
                                    <div className="flex gap-4 flex-col">
                                        <h2 className="font-bold m-0 text-4xl md:text-5xl">Resort-like Atmosphere</h2>
                                        <h2 className="text-black-400 font-semibold text-3xl md:text-4xl">
                                            that provides a haven for focused work and relaxation alike.
                                        </h2>
                                    </div>
                                    <div>
                                        <button
                                            className="font-bold button button_primary px-8 transition text-white ease-in-out bg-indigo-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 "
                                            onClick={executeContactScroll}
                                        >
                                            <a href="#contact__email">Inquire as a Buyer</a>
                                        </button>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                        <div className="relative items-end text-right md:w-2/5">
                            <Parallax speed={30}>
                                <img
                                    src="/images/haley-phelps-S-llxYh3GzI-unsplash.jpg"
                                    alt="Bali Hills"
                                    className="rounded shadow-lg"
                                />
                            </Parallax>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section gray section-invest">
                <div className="wrapper">
                    <div className="content">
                        <div className="py-12 md:p-40 relative items-center text-center flex flex-col gap-8">
                            <h2 className="font-bold text-5xl">Live, grow, and thrive with us.</h2>
                            <button className="button button_primary w-40 transition text-white ease-in-out bg-indigo-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 ">
                                <a href="#contact__email" className="text-md font-bold">
                                    Contact
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="section py-40 md:p-40 relative" ref={teamRef}>
                <div className="headline relative" style={{ zIndex: 5 }}>
                    <h2>Meet the Team</h2>
                </div>

                <div className="wrapper mt-20" style={{ zIndex: 2 }}>
                    <div className="content">
                        <div className="flex flex-col md:flex-row">
                            <div className="flex-1 flex flex-col items-center p-4 gap-4">
                                <div className="p-2 bg-branding rounded-full shadow-2xl border border-solid border-slate-100">
                                    <div
                                        className="avatar p-4 bg-gray-100 rounded-full shadow-2xl"
                                        style={{ backgroundImage: "url('/images/team/ihsan.png')" }}
                                    />
                                </div>
                                <div className="flex flex-col gap-0">
                                    <div className="name font-bold">Ihsan</div>
                                    <div className="title">CEO</div>
                                </div>
                            </div>

                            <div className="flex-1 flex flex-col items-center p-4 gap-4">
                                <div className="p-2 bg-branding rounded-full shadow-2xl border border-solid border-slate-100">
                                    <div
                                        className="avatar p-4 bg-gray-100 rounded-full shadow-2xl"
                                        style={{ backgroundImage: "url('/images/team/stefan.png')" }}
                                    />
                                </div>
                                <div className="flex flex-col gap-0">
                                    <div className="name font-bold">Stefan</div>
                                    <div className="title">CIO</div>
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col items-center p-4 gap-4">
                                <div className="p-2 bg-branding rounded-full shadow-2xl border border-solid border-slate-100">
                                    <div
                                        className="avatar p-4 bg-gray-100 rounded-full shadow-2xl"
                                        style={{ backgroundImage: "url('/images/team/reuben.png')" }}
                                    />
                                </div>
                                <div className="flex flex-col gap-0">
                                    <div className="name font-bold">Reuben</div>
                                    <div className="title">CMO</div>
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col items-center p-4 gap-4">
                                <div className="p-2 bg-branding rounded-full shadow-2xl border border-solid border-slate-100">
                                    <div
                                        className="avatar p-4 bg-gray-100 rounded-full shadow-2xl"
                                        style={{ backgroundImage: "url('/images/team/doyo.png')" }}
                                    />
                                </div>
                                <div className="flex flex-col gap-0">
                                    <div className="name font-bold">Doyo</div>
                                    <div className="title">CFO</div>
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col items-center p-4 gap-4">
                                <div className="p-2 bg-branding rounded-full shadow-2xl border border-solid border-slate-100">
                                    <div
                                        className="avatar p-4 bg-gray-100 rounded-full shadow-2xl"
                                        style={{ backgroundImage: "url('/images/team/russell.png')" }}
                                    />
                                </div>
                                <div className="flex flex-col gap-0">
                                    <div className="name font-bold">Russell</div>
                                    <div className="title">CTO</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="magic-pattern opaicty-10" style={{ zIndex: 1, opacity: 0.4 }}></div>
            </div> */}

            <div className="section" ref={contactRef}>
                <div className="wrapper flex mb-40 flex-col md:flex-row">
                    <div
                        className="flex flex-col gap-8 p-8 pb-40 md:w-1/2 text-center items-center justify-center md:items-start md:justify-start"
                        style={{ zIndex: 88 }}
                    >
                        <h1 style={{ fontSize: '34px', fontWeight: 'bold' }}>
                            Find us <span className="green">everywhere.</span>
                        </h1>

                        <div className="w-full flex items-center md:items-start flex-col gap-1">
                            <p className="font-semibold">Phone or Whatsapp</p>
                            <a href="https://balihills.com">+64123456789</a>
                        </div>

                        <div className="w-full flex items-center md:items-start flex-col gap-1">
                            <p className="font-semibold">Social Media</p>
                            <a href="https://balihills.com">Facebook</a>
                            <a href="https://balihills.com">LinkedIn</a>
                        </div>

                        <div className="w-full flex items-center md:items-start flex-col gap-1">
                            <p className="font-semibold">Address</p>
                            <p>123, Company Street, Company Address, Singapore</p>
                        </div>
                    </div>
                    <div className="flex-1" style={{ zIndex: 99 }}>
                        <Parallax speed={-10}>
                            <div className="flex flex-col gap-8 items-start justify-start shadow-2xl rounded-md p-8 border border-solid border-slate-300 w-full">
                                <h1 style={{ fontSize: '34px', fontWeight: 'bold' }}>Keep in Touch.</h1>
                                {emailSent ? (
                                    <div className="text-center">
                                        <h2>Thank you for your inquiry!</h2>
                                    </div>
                                ) : (
                                    <form ref={form} onSubmit={sendEmail} className="w-full">
                                        <div className="flex flex-col gap-8 items-start justify-start w-full">
                                            <div className="flex-1 w-full flex items-start flex-col gap-1">
                                                <p className="font-semibold">I am inquiring as an</p>
                                                <select
                                                    name="user_type"
                                                    className="bg-slate-100 w-full px-6 py-4 rounded"
                                                >
                                                    <option value="investor">Investor</option>
                                                    <option value="traveller">Traveller</option>
                                                    <option value="buyer">Buyers</option>
                                                </select>
                                            </div>
                                            <div className="flex-1 w-full flex items-start flex-col gap-1">
                                                <p className="font-semibold">Enter Name</p>
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    name="name"
                                                    className="bg-slate-100 w-full px-6 py-4 rounded"
                                                />
                                            </div>
                                            <div className="flex-1 w-full flex items-start flex-col gap-1">
                                                <p className="font-semibold">Enter Email</p>
                                                <input
                                                    type="text"
                                                    placeholder="Email"
                                                    name="email"
                                                    className="bg-slate-100 w-full px-6 py-4 rounded"
                                                />
                                            </div>
                                            <div className="flex-1 w-full flex items-start flex-col gap-1">
                                                <p className="font-semibold">Enter Message</p>
                                                <textarea
                                                    placeholder="Message"
                                                    name="message"
                                                    className="bg-slate-100 w-full px-6 py-4 rounded"
                                                />
                                            </div>

                                            <div>
                                                <input
                                                    type="submit"
                                                    className="font-bold button button_primary px-8 transition text-white ease-in-out bg-indigo-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300 "
                                                    value={loading ? 'Sending...' : 'Send Inquiry'}
                                                    disabled={loading}
                                                    loading={loading}
                                                ></input>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </Parallax>
                    </div>
                </div>
            </div>

            <div id="footer" className="p-8 text-sm">
                2024 Bali Hills by Repose. All rights reserved.
            </div>

            <script>var image = document.getElementsByClassName('thumbnail'); new simpleParallax(image);</script>
        </div>
    );
}

export default App;
